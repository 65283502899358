<template>
  <div class="mb-10">
    <div class="pa-4">
      <navbar-profile :items="items"></navbar-profile>
    </div>
    <div>
      <bullet
        class="px-5"
        :canBack="true"
        :colorIcon="'#052633'"
        :title="'Penerimaan Bayaran'"
      />
      <v-card class="pa-5 mt-4" flat color="#CFE1E1">
        <v-row v-if="!loading && dataSet" no-gutters>
          <v-col cols="12" md="4" class="pa-1">
            <div>
              <h4 class="not_bold">Nama Pelanggan</h4>
              <v-select
                placeholder="Silakan pilih nama pelanggan di sini"
                outlined
                hide-details
                :items="dataPelanggan"
                item-text="nama_lengkap"
                item-value="id"
                v-model="dataSet.klien_costumer_id"
                dense
                class="rounded-lg"
                filled
                readonly
                append-icon=""
              ></v-select>
            </div>
          </v-col>
          <v-col cols="12" md="4" class="pa-1">
            <div>
              <h4 class="not_bold">Setor Ke</h4>
              <v-text-field
                v-model="dataSet.setor_ke"
                placeholder="Silakan tulis di sini"
                hide-details
                dense
                outlined
                class="rounded-lg"
              ></v-text-field>
            </div>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" md="4" class="pa-2">
            <div>
              <h2>Total Tagihan</h2>
              <h3>Rp. {{ formatRupiah(field.total_tagihan) }}</h3>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="loading" no-gutters>
          <v-col cols="12" md="4" v-for="(item, n) in 3" :key="n">
            <v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-card>
      <div class="parent px-5">
        <div class="mt-4 d-flex align-center">
          <v-row no-gutters justify="space-between">
            <v-col cols="12" md="3" class="pr-1">
              <div v-if="!loading && dataSet">
                <h4 class="not_bold">ID Transaksi</h4>
                <v-text-field
                  readonly
                  filled
                  v-model="dataSet.id"
                  placeholder="Isi id transaksi disini"
                  hide-details
                  dense
                  outlined
                  class="rounded-lg"
                ></v-text-field>
              </div>
              <div v-if="loading">
                <v-skeleton-loader
                  class="rounded-lg"
                  width="90px"
                  height="20px"
                  type="image"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  class="rounded-lg mt-1"
                  width="100%"
                  height="40px"
                  type="image"
                ></v-skeleton-loader>
              </div>
            </v-col>
            <v-col cols="12" md="3" class="px-1">
              <div v-if="!loading && dataSet">
                <h4 class="not_bold">Cara Pembayaran</h4>
                <v-text-field
                  v-model="dataSet.cara_bayar"
                  placeholder="Isi cara pembayaran disini"
                  hide-details
                  dense
                  outlined
                  class="rounded-lg"
                ></v-text-field>
              </div>
              <div v-if="loading">
                <v-skeleton-loader
                  class="rounded-lg"
                  width="90px"
                  height="20px"
                  type="image"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  class="rounded-lg mt-1"
                  width="100%"
                  height="40px"
                  type="image"
                ></v-skeleton-loader>
              </div>
            </v-col>
            <v-col cols="12" md="3" class="px-1">
              <div v-if="!loading && dataSet">
                <h4 class="not_bold">Tanggal Pembayaran</h4>
                <v-menu
                  v-model="menuTransaksi"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  max-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="dataSet.tgl_bayar"
                      prepend-inner-icon="mdi-calendar-range"
                      readonly
                      placeholder="Tanggal Pembayaran"
                      hide-details
                      dense
                      v-on="on"
                      outlined
                      class="rounded-lg"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dataSet.tgl_bayar"
                    @input="closeDateMenu(dataSet.tgl_bayar, 'tgl_bayar')"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div v-if="loading">
                <v-skeleton-loader
                  class="rounded-lg"
                  width="90px"
                  height="20px"
                  type="image"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  class="rounded-lg mt-1"
                  width="100%"
                  height="40px"
                  type="image"
                ></v-skeleton-loader>
              </div>
            </v-col>
            <v-col cols="12" md="3" class="px-1">
              <div v-if="!loading && dataSet">
                <h4 class="not_bold">Tanggal Jatuh Tempo</h4>
                <v-menu
                  v-model="menuJatuh"
                  disabled
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                  max-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="dataSet.due_date"
                      prepend-inner-icon="mdi-calendar-range"
                      readonly
                      placeholder="Tanggal Jatuh Tempo"
                      hide-details
                      dense
                      v-on="on"
                      outlined
                      class="rounded-lg"
                      filled
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dataSet.tgl_jatuh"
                    @input="closeDateMenu(dataSet.due_date, 'tgl_jatuh')"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div v-if="loading">
                <v-skeleton-loader
                  class="rounded-lg"
                  width="90px"
                  height="20px"
                  type="image"
                ></v-skeleton-loader>
                <v-skeleton-loader
                  class="rounded-lg mt-1"
                  width="100%"
                  height="40px"
                  type="image"
                ></v-skeleton-loader>
              </div>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </div>
      </div>
    </div>
    <div v-if="!loading && dataSet">
      <v-simple-table
        class="mt-4 pb-2 rounded"
        style="overflow: hidden; border: 1px solid grey"
      >
        <template v-slot:default>
          <thead style="background: #052633">
            <tr>
              <th class="text-left" style="color: white">Layanan</th>
              <th class="text-left" style="color: white">Kuantitas</th>
              <th class="text-left" style="color: white">Harga Satuan</th>
              <th class="text-left" style="color: white">Diskon</th>
              <th class="text-left" style="color: white">Pajak</th>
              <th class="text-left" style="color: white">Jumlah</th>
              <th class="text-left" style="color: white"></th>
            </tr>
          </thead>
          <tbody style="background: white">
            <tr v-for="(item, n) in itemsInvoice" :key="n">
              <td>
                <v-text-field
                  style="min-width: 80px"
                  v-model="item.layanan"
                  placeholder="Tulis layanan disini"
                  hide-details
                  dense
                  outlined
                  class="my-2 rounded-lg"
                  readonly
                  filled
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  style="width: 80px"
                  class="my-2 rounded-lg"
                  readonly
                  filled
                  @blur="countSum(n)"
                  v-model="item.qty"
                  placeholder="Tulis kuantitas"
                  hide-details
                  dense
                  outlined
                  type="number"
                ></v-text-field>
              </td>
              <td>
                <div
                  class="rounded-lg my-2 d-flex align-center"
                  style="height: 70%; border: 1px solid grey; overflow: hidden"
                >
                  <v-card
                    rounded="0"
                    class="px-2 d-flex align-center justify-center"
                    flat
                    color="#052633"
                    style="width: 30px; height: 100%"
                  >
                    <v-icon small color="white">Rp.</v-icon>
                  </v-card>
                  <currency-input
                    :disabled="true"
                    @change="countSum(n)"
                    class_temp="rounded-0"
                    class="pa-2"
                    style="height: 100%; width: 100%"
                    v-model="item.cost"
                    :placeholder="'Tulis harga satuan'"
                    :options="{
                      currency: 'IDR',
                      currencyDisplay: 'hidden',
                      hideCurrencySymbolOnFocus: false,
                      hideGroupingSeparatorOnFocus: true,
                      hideNegligibleDecimalDigitsOnFocus: true,
                      autoDecimalDigits: true,
                      autoSign: true,
                      useGrouping: true,
                      accountingSign: false,
                      precision: 0,
                      locale: 'in',
                      valueRange: { min: 0, max: 999999999999 },
                    }"
                  />
                </div>
              </td>
              <td>
                <v-text-field
                  style="width: 80px"
                  class="my-2 rounded-lg"
                  filled
                  readonly
                  @blur="countSum(n)"
                  outlined
                  v-model="item.diskon"
                  placeholder="Tulis diskon"
                  hide-details
                  dense
                  type="number"
                ></v-text-field>
              </td>
              <td>
                <v-select
                  style="max-width: 120px"
                  class="my-2 rounded-lg"
                  filled
                  readonly
                  placeholder="Pajak"
                  outlined
                  hide-details
                  :items="optPajak"
                  item-text="text"
                  item-value="value"
                  v-model="item.pajak"
                  dense
                  @change="countSum(n)"
                ></v-select>
              </td>
              <td>
                <div
                  class="my-2 rounded-lg d-flex align-center"
                  style="height: 70%; border: 1px solid grey; overflow: hidden"
                >
                  <v-card
                    rounded="0"
                    class="px-2 d-flex align-center justify-center"
                    flat
                    color="#052633"
                    style="width: 30px; height: 100%"
                  >
                    <v-icon small color="white">Rp.</v-icon>
                  </v-card>
                  <currency-input
                    :disabled="true"
                    @change="countSum(n)"
                    class_temp="rounded-0"
                    class="pa-2"
                    style="height: 100%; width: 100%"
                    :placeholder="'Tulis jumlah'"
                    v-model="item.jumlah"
                    :options="{
                      currency: 'IDR',
                      currencyDisplay: 'hidden',
                      hideCurrencySymbolOnFocus: false,
                      hideGroupingSeparatorOnFocus: true,
                      hideNegligibleDecimalDigitsOnFocus: true,
                      autoDecimalDigits: true,
                      autoSign: true,
                      useGrouping: true,
                      accountingSign: false,
                      precision: 0,
                      locale: 'in',
                      valueRange: { min: 0, max: 999999999999 },
                    }"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <div class="mt-4 px-3" v-if="!loading && dataSet">
      <v-row no-gutters>
        <v-col cols="12" md="6">
          <div class="pa-2">
            <h4 class="not_bold">Notes</h4>
            <v-textarea
              rows="4"
              auto-grow
              v-model="dataSet.note"
              placeholder="Isi note disini"
              hide-details
              dense
              outlined
              readonly
              filled
              class="rounded-lg"
            ></v-textarea>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="pa-2">
            <div class="mt-2 d-flex justify-space-between">
              <h4>Total Tagihan</h4>
              <h4>Rp. {{ formatRupiah(field.total_tagihan) }}</h4>
            </div>
            <v-divider class="my-4"></v-divider>
          </div>
        </v-col>
        <v-col cols="12" md="12">
          <div v-if="!loading && dataSet" class="mt-4 d-flex justify-end">
            <v-btn
              min-width="200px"
              outlined
              color="#BD9028"
              class="text-capitalize mr-2"
              @click="$router.go(-1)"
            >
              Batal
            </v-btn>
            <v-btn
              :disabled="!loadingCreate ? false : true"
              @click="updateData"
              min-width="200px"
              color="#052633"
              class="text-capitalize mr-2"
            >
              <h4 class="txt_white">Buat Penerimaan</h4>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-skeleton-loader v-if="loading" type="table"></v-skeleton-loader>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CurrencyInput from "../../components/currencyInput/CurrencyInput.vue";
import bullet from "../../components/Etc/bullet.vue";
import NavbarProfile from "../../components/navbarProfile/navbarProfile.vue";
export default {
  components: { bullet, NavbarProfile, CurrencyInput },
  name: "createInvoice",
  computed: {
    ...mapState({
      drawer: (state) => state.drawer,
    }),
  },
  data() {
    return {
      d_create: false,
      d_type: "",
      d_title: "",
      inventory: "",
      line: "",
      loading: false,
      loadingCreate: false,
      dataSet: null,
      menuTransaksi: false,
      menuJatuh: false,
      itemsInvoice: [
        {
          id: null,
          layanan_id: null,
          layanan: "",
          jenis: "",
          qty: 0,
          cost: 0,
          diskon: 0,
          pajak: 0,
          jumlah: 0,
        },
      ],
      dataPelanggan: null,
      optPajak: [
        { text: "PPN", value: 11 },
        { text: "Kosong", value: 0 },
      ],
      field: {
        total: 0,
        total_diskon: 0,
        sum_pajak: 0,
        total_tagihan: 0,
        diskon_tamhahan_txt: 0,
      },
      items: [
        {
          text: "Home",
          disabled: false,
          href: "/",
        },
        {
          text: "Invoice",
          disabled: false,
          href: "/admin/mku/invoice",
        },
        {
          text: "Detail Invoice",
          disabled: false,
          href: `/admin/mku/invoice/detail/${this.$route.params.idInvoice}`,
        },
        {
          text: "Penerimaan Invoice",
          disabled: true,
          href: "#",
        },
      ],
    };
  },
  mounted() {
    this.fetchDataCustomer();
  },
  methods: {
    formatRupiah(angka) {
      var temp_angka = angka.toString().replace(".", ",");
      var number_string = temp_angka,
        split = number_string.split(","),
        sisa = split[0].length % 3,
        rupiah = split[0].substr(0, sisa),
        ribuan = split[0].substr(sisa).match(/\d{1,3}/gi);

      if (ribuan) {
        let separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }
      rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
      return rupiah;
    },
    fetchData() {
      this.loading = true;
      let data = {
        path: `sysadmin/admin/invoice/get?filter[id]=${this.$route.params.idInvoice}`,
      };
      this.$store
        .dispatch("officer/getData", data)
        .then((data) => {
          console.log(data.data[0]);
          this.dataSet = data.data[0];
          this.itemsInvoice = [];
          this.dataSet.item.forEach((item, idx) => {
            this.itemsInvoice.push({
              id: item.id,
              layanan: item.name,
              layanan_id: item.layanan_id,
              jenis: item.jenis,
              qty: item.qty,
              cost: item.cost,
              diskon: item.diskon,
              pajak: item.pajak,
              jumlah: 0,
            });
            this.countSum(idx);
          });
          this.loading = false;
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    closeDateMenu(item, type) {
      if (type == "tgl_bayar") {
        this.menuTransaksi = false;
        this.dataSet.tgl_bayar == ""
          ? this.$date(item).format("dddd, MMMM Do YYYY")
          : "";
      } else {
        this.menuJatuh = false;
        this.field.tgl_jatuh == ""
          ? this.$date(item).format("dddd, MMMM Do YYYY")
          : "";
      }
    },
    fetchDataCustomer() {
      this.loading = true;
      let data = {
        path: `sysadmin/customer/list`,
      };
      this.$store
        .dispatch("officer/getData", data)
        .then((data) => {
          this.dataPelanggan = data.data;
          this.fetchData();
        })
        .catch((e) => {
          this.$store.dispatch("notify", {
            msg: e.message,
            type: "error",
          });
          this.loading = false;
        });
    },
    countSum(n) {
      let sumHarga = 0;
      let sumDiskon = 0;
      let sumPpn = 0;
      if (n != -2) {
        this.itemsInvoice[n].jumlah =
          this.itemsInvoice[n].qty * this.itemsInvoice[n].cost;
      }
      this.itemsInvoice.forEach((item) => {
        sumHarga = parseInt(item.jumlah) + parseInt(sumHarga);
        sumDiskon =
          (parseInt(item.diskon) * (parseInt(item.qty) * parseInt(item.cost))) /
            100 +
          parseInt(sumDiskon);
        sumPpn = parseInt(item.pajak) + parseInt(sumPpn);
      });

      this.field.total = sumHarga;
      this.field.total_diskon = sumDiskon;
      if (this.dataSet.persen_diskon == 1) {
        this.field.diskon_tamhahan_txt =
          ((this.field.total - this.field.total_diskon) * this.dataSet.diskon) /
          100;
      } else {
        this.field.diskon_tamhahan_txt = this.dataSet.diskon;
      }
      this.field.sum_pajak =
        ((this.field.total -
          this.field.total_diskon -
          this.field.diskon_tamhahan_txt) *
          sumPpn) /
        100;
      this.field.total_tagihan =
        this.field.total -
        this.field.total_diskon -
        this.field.diskon_tamhahan_txt -
        this.field.sum_pajak;
    },
    updateData() {
      this.loadingCreate = true;
      let itemsInvoice = [];
      this.itemsInvoice.forEach((item) => {
        if (item.layanan) {
          itemsInvoice.push({
            id: item.id,
            name: item.layanan,
            layanan_id: item.layanan_id,
            jenis: item.jenis,
            qty: item.qty,
            cost: item.cost,
            diskon: item.diskon,
            pajak: item.pajak,
          });
        }
      });
      let data = {
        body: {
          id: this.$route.params.idInvoice,
          klien_costumer_id: this.dataSet.klien_costumer_id,
          due_date: this.dataSet.due_date,
          status: 1,
          note: this.dataSet.note,
          array_json: itemsInvoice,
          payment_method_id: 1,
          tgl_transaksi: this.dataSet.tgl_transaksi,
          persen_diskon: this.dataSet.persen_diskon,
          diskon: this.dataSet.diskon,
          persen_potongan: this.dataSet.persen_potongan,
          potongan: this.dataSet.potongan,
          is_cut: this.dataSet.is_cut,
          total_tagihan: this.dataSet.total_tagihan,
          cara_bayar: this.dataSet.cara_bayar,
          setor_ke: this.dataSet.setor_ke,
          tgl_bayar: this.dataSet.tgl_bayar,
        },
        path: `sysadmin/admin/invoice/update`,
      };
      this.$store
        .dispatch("client/postApi", data)
        .then((data) => {
          this.loadingCreate = false;
          if (data.status != "success") {
            if (data.data) {
              var key = Object.keys(data.data.errors);
              var values = Object.values(data.data.errors);
              for (let i = 0; i < key.length; i++) {
                for (let j = 0; j < values[i].length; j++) {
                  this.$toast.error(values[i][j]);
                }
              }
            } else {
              this.$store.dispatch("notify", {
                msg: data.message,
                type: "error",
              });
            }
          } else {
            this.loadingCreate = false;
            this.$store.dispatch("notify", {
              msg: data.message,
              type: "success",
            });
          }
        })
        .catch((e) => {
          this.loadingCreate = false;
          if (e.data) {
            var key = Object.keys(e.data.errors);
            var values = Object.values(e.data.errors);
            for (let i = 0; i < key.length; i++) {
              for (let j = 0; j < values[i].length; j++) {
                this.$toast.error(values[i][j]);
              }
            }
          } else {
            this.$store.dispatch("notify", {
              msg: e.message,
              type: "error",
            });
          }
        });
    },
  },
};
</script>

<style scoped>
.v-data-table tbody tr:nth-child(even) {
  background-color: white !important;
}
.parent {
  width: 80%;
}
@media (max-width: 960px) {
  .parent {
    width: 100%;
  }
}
</style>
